import {collection, getDocs} from "@firebase/firestore";

const COLLECTION_NAME = 'configuration';

export const ConfigurationRepository = {
    getAllowedUsers: async (db: any): Promise<string[] | null> => {
        try {
            const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
            let allowedUsers: string[] = [];
            querySnapshot.forEach((doc) => {
                allowedUsers.push(doc.data().email);
            });

            return allowedUsers;
        } catch (error) {
            console.error('Error getting allowed users:', error);
            return null;
        }
    },
};