import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getAuth, signOut } from "@firebase/auth";
import "./NavBar.scss";

const NavBarComponent = () => {
  const auth = getAuth();
  const location = useLocation();

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-custom">
      <Navbar.Brand className="navbar-custom__brand" as={Link} to="/">
        z miłości do chleba
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="navbar-custom__links-container" activeKey={location.pathname}>
          <Nav.Link as={Link} eventKey="/assortment" to="/assortment">
            Asortyment
          </Nav.Link>
          <Nav.Link as={Link} eventKey="/recipes" to="/recipes">
            Receptury
          </Nav.Link>
          <Nav.Link as={Link} eventKey="/ingredients" to="/ingredients">
            Składniki
          </Nav.Link>
          <Nav.Link as={Link} eventKey="/clients" to="/clients">
            Klienci
          </Nav.Link>
          <Nav.Link as={Link} eventKey="/orders" to="/orders">
            Zamówienia
          </Nav.Link>
          <Nav.Link as={NavLink} onClick={async () => await signOut(auth)}>
            Wyloguj
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarComponent;
