import React, {createContext, useEffect, useState} from 'react';
import {initializeApp} from '@firebase/app';
import {getAuth, onAuthStateChanged} from '@firebase/auth';
import {config} from "../config/config";
import {Spinner} from "react-bootstrap";
import {ConfigurationRepository} from "../repositories/configuration";
import {Firestore, getFirestore} from "@firebase/firestore";

interface AuthenticatedUser {
    email: string;
    hasAccess: boolean;
}

export interface FirebaseContextType {
    user: AuthenticatedUser | null;
    db: Firestore;
}

interface FirebaseProviderProps {
    children: React.ReactNode;
}

export const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({children}: FirebaseProviderProps) => {
    const firebaseApp = initializeApp(config.firebaseConfig);
    const auth = getAuth(firebaseApp);

    const [context, setContext] = useState<FirebaseContextType | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const db: Firestore = getFirestore(firebaseApp);

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            try {
                setContext(null);
                if (!user) setContext(null);
                else {
                    const hasAccess = await checkIfUserIsValid(db, user.email);
                    const authUser = {
                        email: user.email ?? "",
                        hasAccess: hasAccess
                    }

                    setContext({user: authUser, db: db});
                }

            } catch ({message}) {
                console.log(message);
            } finally {
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const checkIfUserIsValid = async (db: Firestore, email: string | null) => {
        if (!email) return false;
        let allowed = false;

        try {
            const allowedUsers = await ConfigurationRepository.getAllowedUsers(db);
            if (allowedUsers) {
                allowedUsers.forEach((account: any) => {
                    if (email.toLowerCase() === account.toLowerCase()) {
                        allowed = true;
                    }
                });
            }
        } catch ({message}) {
            console.log(message);
        } finally {
            return allowed;
        }

        return allowed;
    }

    if (isLoading) {
        return <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <Spinner/>
        </div>
    }

    return (
        <FirebaseContext.Provider value={context}>
            {children}
        </FirebaseContext.Provider>
    );
};