import React, { lazy, Suspense, useContext } from "react";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { FirebaseProvider, FirebaseContext, FirebaseContextType } from "./components/FirebaseProvider";
import NavBar from "./components/NavBar";
import AuthChecker from "./components/AuthChecker";
import { Spinner } from "react-bootstrap";

const HomePage = lazy(() => import("./features/Home"));
const LoginPage = lazy(() => import("./features/Login"));
const RecipesPage = lazy(() => import("./features/Recipes"));
const IngredientsPage = lazy(() => import("./features/Ingredients"));
const AssortmentPage = lazy(() => import("./features/Assortment"));
const ClientsPage = lazy(() => import("./features/Clients"));
const OrdersPage = lazy(() => import("./features/Orders"));
const AccessDeniedPage = lazy(() => import("./features/AccessDenied"));

const Root = () => {
  return (
    <div className="root-container">
      <FirebaseProvider>
        <App />
      </FirebaseProvider>
    </div>
  );
};

const App = () => {
  const context: FirebaseContextType | null = useContext(FirebaseContext);
  const hasAccess = context?.user?.hasAccess ?? false;

  return (
    <div className="container pb-4">
      <BrowserRouter>
        <FirebaseProvider>
          {hasAccess && <NavBar />}
          <AuthChecker>
            <Routes>
              {hasAccess && (
                <>
                  <Route
                    path="/"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <HomePage />
                      </Suspense>
                    }
                  />
                  ,
                  <Route
                    key="/assortment"
                    path="/assortment"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <AssortmentPage />
                      </Suspense>
                    }
                  />
                  ,
                  <Route
                    key="/recipes"
                    path="/recipes"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <RecipesPage />
                      </Suspense>
                    }
                  />
                  ,
                  <Route
                    key="/ingredients"
                    path="/ingredients"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <IngredientsPage />
                      </Suspense>
                    }
                  />
                  ,
                  <Route
                    key="/clients"
                    path="/clients"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <ClientsPage />
                      </Suspense>
                    }
                  />
                  ,
                  <Route
                    key="/orders"
                    path="/orders"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <OrdersPage />
                      </Suspense>
                    }
                  />
                </>
              )}
              <Route
                path="/login"
                element={
                  <Suspense fallback={<Spinner />}>
                    <LoginPage />
                  </Suspense>
                }
              />
              <Route
                path="/access-denied"
                element={
                  <Suspense fallback={<Spinner />}>
                    <AccessDeniedPage />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to={"/login"} />} />
            </Routes>
          </AuthChecker>
        </FirebaseProvider>
      </BrowserRouter>
    </div>
  );
};

export default Root;
