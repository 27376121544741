import React, {useContext, useEffect} from 'react';
import {FirebaseContext} from './../components/FirebaseProvider';
import {useLocation, useNavigate} from 'react-router-dom';

interface IWithAuthCheckProps {
    children: React.ReactNode;
}

const AuthChecker: React.FC<IWithAuthCheckProps> = ({children}) => {
    const authContext = useContext(FirebaseContext);
    const navigate = useNavigate();
    const location = useLocation();


    const isUserAuthenticated = authContext?.user != null ?? false;
    let isUserHasAccess = authContext?.user?.hasAccess ?? false;

    useEffect(() => {
        if (isUserAuthenticated && isUserHasAccess) {
            navigate('/');
        }
        if (!isUserAuthenticated) {
            navigate('/login');
        }
        if (isUserAuthenticated && !isUserHasAccess) {
            navigate('/access-denied');
        }
    }, [authContext]);

    return <>{children}</>;
};

export default AuthChecker;
