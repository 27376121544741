export const config = {
    firebaseConfig: {
        apiKey: "AIzaSyDD2FDeNbntVwRplCyQfEpqhK5EXGSudZo",
        authDomain: "z-milosci-do-chleba.firebaseapp.com",
        databaseURL: "https://z-milosci-do-chleba-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "z-milosci-do-chleba",
        storageBucket: "z-milosci-do-chleba.appspot.com",
        messagingSenderId: "1062711954104",
        appId: "1:1062711954104:web:682e2070e2de0a791b024d"
    }
}